/**=====================
    2.21 Loader CSS Start
==========================**/
.loader-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: $white;
  z-index: 11;
  top: 0;

  .loader {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 75px;
    display: inline-block;
    vertical-align: middle;
  }

  .loader-1 .loader-outter {
    position: absolute;
    border:4px solid #E82c86;
    border-left-color: transparent;
    border-bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: loader-1-outter 1.5s cubic-bezier(.42, .61, .58, .41) infinite;
  }

  .loader-1 .loader-inner {
    position: absolute;
    border: 4px solid #E82c86;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    border-right: 0;
    border-top-color: 4px solid #E82c86 ;
    animation: loader-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
  }

  .loader-1 .artium .loder {
    width: 40px;
    height: 40px;
    border: 4px solid #E82c86; /* Light gray background */
    border-top : 4px solid #E82c86;
    border-radius: 50%;
    animation: spin 1s linear infinite; /* Spin animation */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

  .loader-1 .loader-inner-1 {
    position: absolute;

    border: 4px solid #E82c86;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
    border-right: 0;
    border-top-color: transparent;
    animation: loader-1-inner 2s cubic-bezier(.42, .61, .58, .41) infinite;
  }

}

@keyframes loader-1-inner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes loader-1-outter {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/**=====================
    2.21 Loader CSS Ends
==========================**/