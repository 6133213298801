/**=====================
   2.31 Toasts CSS Start
==========================**/

.toast {
    width: 350px;
    max-width: 100%;
    font-size: .875rem;
    pointer-events: auto;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px dashed rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 0.25rem;
}

.toast-index {
    z-index: 12;

    &.toast:not(.showing):not(.show) {
        opacity: 0;
    }

    &.toast-fade {
        transition: opacity 0.15s linear;
    }

    &.show {
        display: block;
    }
}

.toast-img {
    img {
        width: 30px;
        height: 30px;
    }
}

.bd-example-toasts {
    min-height: 400px;
}

.toast:not(.showing):not(.show) {
    opacity: 0;
}

.toast-content {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 65px;
    margin: 0 auto;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.toast-container {
    position: unset;
}

.toast-rtl {
    .toast {
        .toast-header {
            strong {
                [dir="rtl"] & {
                    margin-right: unset !important;
                    margin-left: auto !important;
                }
            }
        }

        .btn-close {
            [dir="rtl"] & {
                margin-right: 6px;
                margin-left: unset;
            }
        }
    }
}

.toast-dark {
    .btn {
        @media (max-width:1920px) {
            padding: calc(6px + (6 - 6) * ((100vw - 320px) / (1920 - 320))) calc(4px + (28 - 4) * ((100vw - 320px) / (1920 - 320)));
        }
    }
}

.toast-icons {
    margin-right: 6px;
    width: 20px;
    height: 20px;

    [dir="rtl"] & {
        margin-right: unset;
        margin-left: 6px;
    }
}

/**=====================
   2.31 Toasts CSS Ends
==========================**/