/**=====================
	2.14 Dropdown CSS Start
==========================**/
.img-cropper {
    .dropup {
        .dropdown-toggle {
            &::after {
                content: '';
                display: none;
            }
        }
    }
}
.dropdown-auto {
    overflow: auto;
}
.dropdown-divider {
    margin: 0;
}
.shifts-char-box {
    .d-flex {
        div {
            &:first-child {
                &.dropdown{
                    .dropdown-toggle {
                        background-color: rgba(115, 102, 255, 0.08);
                        color: $primary-color;
                        border-radius: 10px;
                        padding: 5px 10px !important;
                        width: 100px;
                        text-align: left;
                        font-family: $font-outfit, $font-serif;
                
                        &::before {
                            position: absolute;
                            top: 50%;
                            right: 10px;
                            -webkit-transform: translateY(-50%);
                            transform: translateY(-50%);
                            content: "\f107";
                            border: none;
                            font-family: "FontAwesome";
                            font-size: 18px;
                            margin: 0;
                        }
                    }
                
                    .dropdown-menu {
                        font-family: $font-outfit, $font-serif;
                    } 
                }
            }
        }
    }
}
.dropdown .dropdown-menu {
    font-family: Outfit, sans-serif;
}
.dropdown-basic {
    .separated-btn {
        .btn {
            width: 32px;
            overflow: hidden;
        }
    }
}
.docs-toggles {
    .dropdown {
        .dropdown-toggle {
            width: auto;
        }
    }
}
.nav-tabs {
    .dropdown {
        .dropdown-toggle {
            width: 120px;
            @media only screen and (max-width: 575.98px) {
                width: 100%;
                text-align: center;
            }
        }
    }
}
.icon-dropdown {
    .dropdown-toggle {
        background: transparent;
        color: $theme-body-sub-title-color;
        padding: 0 !important;
        width: auto;
        i {
            font-size: 16px;
        }
        &::after {
            display: none;
        }
    }
    .dropdown-item {
        line-height: 1.5;
    }
}
.dropdown-basic {
    margin-bottom: -10px;
    .btn-group {
        .btn-round {
            border-radius: 50px;
        }
    }
    .separated-btn {
        margin-left: -6px;
        [dir="rtl"] & {
            margin-left: unset;
            margin-right: -6px;
        }
        .btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            padding: 10px !important;
        }
    }
    button {
        max-height: 43px;
    }
    .dropdown {
        position: relative;
        display: inline-block;
        margin-bottom: 10px;
        .dropbtn {
            color: white;
            padding: 12px 35px;
            border: none;
            cursor: pointer;
        }
        .dropdown-content {
            display: none;
            position: absolute;
            right: 0;
            background-color: #f9f9f9;
            min-width: 175px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            left: 0;
            top: 45px;
            a {
                color: black;
                padding: 12px 16px;
                text-decoration: none;
                display: block;
            }
            .dropdown-header {
                padding: 12px 16px;
            }
        }
    }
    .dropdown-content a:hover {
        background-color: #f1f1f1;
    }
    .dropdown:hover .dropdown-content {
        display: block;
    }
}
.dropup-basic {
    .dropup {
        position: relative;
        display: inline-block;
        .dropbtn {
            color: white;
            padding: 12px;
            border: none;
        }
        .dropup-content {
            display: none;
            position: absolute;
            background-color: #f9f9f9;
            min-width: 170px;
            bottom: 45px;
            z-index: 999;
            left: 0;
            a {
                color: black;
                padding: 12px 16px;
                text-decoration: none;
                display: block;
            }
        }
    }
    .dropup .dropup-content a:hover {
        background-color: $light-gray;
    }
    .dropup:hover .dropup-content {
        display: block;
        a {
            &.active {
                background-color: $light-gray;
            }
        }
    }
}
// dropdown
.dropdown-block {
    li {
        .dropdown-item {
            &:hover {
                background-color: $gray-60;
            }
        }
        .helper-truncate {
            &:hover {
                background-color: unset !important;
            }
        }
    }
}
.dropdown-basic {
    .dropdown {
        .dropdown-content {
            a {
                &:hover {
                    background-color: $gray-60 !important;
                }
            }
        }
    }
}
.dropdown-menu {
    .input-group {
        background-color: unset !important;
        padding: 6px 12px;
        border-top: 1px solid $light-semi-gray;
        background-color: $white;
        &:hover,
        &:active {
            background-color: $gray-60;
        }
        .input-group-text {
            background-color: unset;
            border: none;
        }
    }
}
.dropdown-toggle {
    &::after {
        display: inline-block;
        margin-left: 8px;
        vertical-align: 4px;
        content: '';
        border-top: 4px solid;
        border-right: 4px solid transparent;
        border-bottom: 0;
        border-left: 4px solid transparent;
        [dir="rtl"] & {
            margin-left: unset;
            margin-right: 8px;
        }
    }
}
.dropstart {
    .dropdown-toggle {
        &::before {
            display: inline-block;
            margin-right: 8px;
            content: '';
            border-top: 4px solid transparent;
            border-right: 4px solid;
            border-bottom: 4px solid transparent;
            [dir="rtl"] & {
                margin-left: 8px;
                margin-right: unset;
            }
        }
    }
}
.dropup {
    .dropdown-toggle {
        &::after {
            content: '';
            display: inline-block;
            margin-left: 8px;
            vertical-align: 4px;
            border-top: 0;
            border-right: 4px solid transparent;
            border-bottom: 4px solid;
            border-left: 4px solid transparent;
            [dir="rtl"] & {
                margin-left: unset;
                margin-right: 8px;
            }
        }
    }
}
.dropend {
    .dropdown-toggle {
        &::after {
            content: '';
            display: inline-block;
            content: '';
            border-top: 4px solid transparent;
            border-right: 0;
            border-bottom: 4px solid transparent;
            border-left: 4px solid;
            margin-left: 8px;
            [dir="rtl"] & {
                margin-left: unset;
                margin-right: 8px;
            }
        }
    }
}
.form-wrapper {
    width: 260px;
}
.dropdown-menu {
    .helper-truncate {
        @media (max-width:400px) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
.dark-dropdown {
    .dropdown-block {
        li {
            .dropdown-item {
                &:hover,
                &.active {
                    background-color: $dark-color;
                    color: $white;
                }
            }
        }
    }
}
btn-group {
    .dropdown-wrapper {
        z-index: 2;
    }
}
.rtl-dropdown {
    .dropdown-menu {
        li {
            .dropdown-item {
                [dir="rtl"] & {
                    text-align: right;
                }
            }
        }
    }
    .btn-group {
        .dropdown-menu {
            [dir="rtl"] & {
                text-align: right;
            }
        }
    }
}
/**=====================
	2.14 Dropdown CSS Ends
==========================**/