.default-dashboard {

    .datepicker--cell-day.-other-month-,
    .datepicker--cell-year.-other-decade- {
        color: #dedede;
    }

    .datepicker--cell-day.-other-month-:hover,
    .datepicker--cell-year.-other-decade-:hover {
        color: #c5c5c5;
    }

    .-disabled-.-focus-.datepicker--cell-day.-other-month-,
    .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
        color: #dedede;
    }

    .-selected-.datepicker--cell-day.-other-month-,
    .-selected-.datepicker--cell-year.-other-decade- {
        color: #fff;
        background: #a2ddf6;
    }

    .datepicker--cell {
        &.-current- {
            border: 2px solid $warning-color;
        }
    }


    .-selected-.-focus-.datepicker--cell-day.-other-month-,
    .-selected-.-focus-.datepicker--cell-year.-other-decade- {
        background: #8ad5f4;
    }

    .-in-range-.datepicker--cell-day.-other-month-,
    .-in-range-.datepicker--cell-year.-other-decade- {
        background-color: rgba(92, 196, 239, 0.1);
        color: #cccccc;
    }

    .-in-range-.-focus-.datepicker--cell-day.-other-month-,
    .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
        background-color: rgba(92, 196, 239, 0.2);
    }

    .datepicker--cell-day.-other-month-:empty,
    .datepicker--cell-year.-other-decade-:empty {
        background: none;
        border: none;
    }

    /* -------------------------------------------------
        Datepicker cells
       ------------------------------------------------- */
    .datepicker--cells {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .datepicker--cell {
        border-radius: 8px;
        box-sizing: border-box;
        cursor: pointer;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        position: relative;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        font-weight: 700;
        color: $theme-body-font-color;
        height: 46px;
        z-index: 1;

        [class="dark-only"] & {
            color: rgba($white, 0.6);
        }
    }

    .datepicker--cell.-focus- {
        background: $primary-color;
        color: $white;
    }

    .datepicker--cell.-current- {
        color: $warning-color;
    }

    .datepicker--cell.-current-.-focus- {
        color: #4a4a4a;
    }

    .datepicker--cell.-current-.-in-range- {
        color: #4eb5e6;
    }

    .datepicker--cell.-in-range- {
        background: rgba($primary-color, 0.1);
        color: #4a4a4a;
        border-radius: 0;

        [class="dark-only"] & {
            color: rgba($white, 0.6);
        }

        &:first-child {
            border-radius: 25px 0 0 25px;
        }

        &:last-child {
            border-radius: 0 25px 25px 0;
        }
    }

    .datepicker--cell.-in-range-.-focus- {
        background-color: rgba($primary-color, 0.2);
    }

    .datepicker--cell.-disabled- {
        cursor: default;
        color: $secondary-color;
    }

    .datepicker--cell.-disabled-.-focus- {
        color: #aeaeae;
    }

    .datepicker--cell.-disabled-.-in-range- {
        color: #a1a1a1;
    }

    .datepicker--cell.-disabled-.-current-.-focus- {
        color: #aeaeae;
    }

    .datepicker--cell.-range-from- {
        border: 1px solid rgba($primary-color, 0.5);
        background-color: rgba($primary-color, 0.1);
        border-radius: 25px 0 0 25px;
    }

    .datepicker--cell.-range-to- {
        border: 1px solid rgba($primary-color, 0.5);
        background-color: rgba($primary-color, 0.1);
        border-radius: 0 25px 25px 0;
        color: $primary-color;
    }

    .datepicker--cell.-range-from-.-range-to- {
        border-radius: 4px;
    }

    .datepicker--cell.-selected- {
        color: #fff;
        border: none;
        background: #5cc4ef;
    }

    .datepicker--cell.-selected-.-current- {
        color: #fff;
        background: #5cc4ef;
    }

    .datepicker--cell.-selected-.-focus- {
        background: #45bced;
    }

    .datepicker--cell:empty {
        cursor: default;
    }

    .datepicker--days-names {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 8px 0 3px;
    }

    .datepicker--day-name {
        color: $light-text-color;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: center;
        text-transform: uppercase;
        font-size: 0.8em;
    }

    .datepicker--cell-day {
        width: 14.28571%;
    }

    .datepicker--cells-months {
        height: 170px;
    }

    .datepicker--cell-month {
        width: 33.33%;
        height: 25%;
    }

    .datepicker--years {
        height: 170px;
    }

    .datepicker--cells-years {
        height: 170px;
    }

    .datepicker--cell-year {
        width: 25%;
        height: 33.33%;
    }

    .datepicker--cell-day.-other-month-,
    .datepicker--cell-year.-other-decade- {
        color: #dedede;
    }

    .datepicker--cell-day.-other-month-:hover,
    .datepicker--cell-year.-other-decade-:hover {
        color: #c5c5c5;
    }

    .-disabled-.-focus-.datepicker--cell-day.-other-month-,
    .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
        color: #dedede;
    }

    .-selected-.datepicker--cell-day.-other-month-,
    .-selected-.datepicker--cell-year.-other-decade- {
        color: #fff;
        background: #a2ddf6;
    }

    .-selected-.-focus-.datepicker--cell-day.-other-month-,
    .-selected-.-focus-.datepicker--cell-year.-other-decade- {
        background: #8ad5f4;
    }

    .-in-range-.datepicker--cell-day.-other-month-,
    .-in-range-.datepicker--cell-year.-other-decade- {
        background-color: rgba(92, 196, 239, 0.1);
        color: #cccccc;
    }

    .-in-range-.-focus-.datepicker--cell-day.-other-month-,
    .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
        background-color: rgba(92, 196, 239, 0.2);
    }

    .datepicker--cell-day.-other-month-:empty,
    .datepicker--cell-year.-other-decade-:empty {
        background: none;
        border: none;
    }

    /* -------------------------------------------------
        Datepicker
       ------------------------------------------------- */
    .datepickers-container {
        position: absolute;
        left: 0;
        top: 0;
    }

    @media print {
        .datepickers-container {
            display: none;
        }
    }

    .datepicker {
        background: transparent;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        box-sizing: content-box;
        font-family: Tahoma, sans-serif;
        font-size: 14px;
        color: #4a4a4a;
        width: 100%;
        position: absolute;
        left: -100000px;
        opacity: 0;
        transition: opacity 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
        transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s;
        transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s,
            -webkit-transform 0.3s ease;
        z-index: 100;
    }

    .datepicker.-from-top- {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }

    .datepicker.-from-right- {
        -webkit-transform: translateX(8px);
        transform: translateX(8px);
    }

    .datepicker.-from-bottom- {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }

    .datepicker.-from-left- {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
    }

    .datepicker.active {
        opacity: 1;
        -webkit-transform: translate(0);
        transform: translate(0);
        transition: opacity 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease;
        transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s;
        transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s,
            -webkit-transform 0.3s ease;
    }

    .datepicker-inline .datepicker {
        border-color: #d7d7d7;
        box-shadow: none;
        position: static;
        left: auto;
        right: auto;
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }

    .datepicker-inline .datepicker--pointer {
        display: none;
    }

    .datepicker--content {
        box-sizing: content-box;
        padding: 10px 4px 4px;
        background: transparent;
    }

    .-only-timepicker- .datepicker--content {
        display: none;
    }

    .datepicker--pointer {
        position: absolute;
        background: #fff;
        border-top: 1px solid #dbdbdb;
        border-right: 1px solid #dbdbdb;
        width: 10px;
        height: 10px;
        z-index: -1;
    }

    .-top-left- .datepicker--pointer,
    .-top-center- .datepicker--pointer,
    .-top-right- .datepicker--pointer {
        top: calc(100% - 4px);
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }

    .-right-top- .datepicker--pointer,
    .-right-center- .datepicker--pointer,
    .-right-bottom- .datepicker--pointer {
        right: calc(100% - 4px);
        -webkit-transform: rotate(225deg);
        transform: rotate(225deg);
    }

    .-bottom-left- .datepicker--pointer,
    .-bottom-center- .datepicker--pointer,
    .-bottom-right- .datepicker--pointer {
        bottom: calc(100% - 4px);
        -webkit-transform: rotate(315deg);
        transform: rotate(315deg);
    }

    .-left-top- .datepicker--pointer,
    .-left-center- .datepicker--pointer,
    .-left-bottom- .datepicker--pointer {
        left: calc(100% - 4px);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .-top-left- .datepicker--pointer,
    .-bottom-left- .datepicker--pointer {
        left: 10px;
    }

    .-top-right- .datepicker--pointer,
    .-bottom-right- .datepicker--pointer {
        right: 10px;
    }

    .-top-center- .datepicker--pointer,
    .-bottom-center- .datepicker--pointer {
        left: calc(50% - 10px / 2);
    }

    .-left-top- .datepicker--pointer,
    .-right-top- .datepicker--pointer {
        top: 10px;
    }

    .-left-bottom- .datepicker--pointer,
    .-right-bottom- .datepicker--pointer {
        bottom: 10px;
    }

    .-left-center- .datepicker--pointer,
    .-right-center- .datepicker--pointer {
        top: calc(50% - 10px / 2);
    }

    .datepicker--body {
        display: none;
    }

    .datepicker--body.active {
        display: block;
    }

    .datepicker--cell-day.-other-month-,
    .datepicker--cell-year.-other-decade- {
        color: #dedede;
    }

    .datepicker--cell-day.-other-month-:hover,
    .datepicker--cell-year.-other-decade-:hover {
        color: #c5c5c5;
    }

    .-disabled-.-focus-.datepicker--cell-day.-other-month-,
    .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
        color: #dedede;
    }

    .-selected-.datepicker--cell-day.-other-month-,
    .-selected-.datepicker--cell-year.-other-decade- {
        color: #fff;
        background: #a2ddf6;
    }

    .-selected-.-focus-.datepicker--cell-day.-other-month-,
    .-selected-.-focus-.datepicker--cell-year.-other-decade- {
        background: #8ad5f4;
    }

    .-in-range-.datepicker--cell-day.-other-month-,
    .-in-range-.datepicker--cell-year.-other-decade- {
        background-color: rgba(92, 196, 239, 0.1);
        color: #cccccc;
    }

    .-in-range-.-focus-.datepicker--cell-day.-other-month-,
    .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
        background-color: rgba(92, 196, 239, 0.2);
    }

    .datepicker--cell-day.-other-month-:empty,
    .datepicker--cell-year.-other-decade-:empty {
        background: none;
        border: none;
    }

    /* -------------------------------------------------
        Navigation
       ------------------------------------------------- */
    .datepicker--nav {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        min-height: 32px;
        padding: 4px;
    }

    .-only-timepicker- .datepicker--nav {
        display: none;
    }

    .datepicker--nav-title,
    .datepicker--nav-action {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        cursor: pointer;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        font-weight: 800;
        color: $theme-body-font-color;
    }

    .datepicker--nav-action {
        width: 32px;
        border-radius: 4px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .datepicker--nav-action:hover {
        background: #f0f0f0;
    }

    .datepicker--nav-action.-disabled- {
        visibility: hidden;
    }

    .datepicker--nav-action svg {
        width: 32px;
        height: 32px;
    }

    .datepicker--nav-action path {
        fill: none;
        stroke: #9c9c9c;
        stroke-width: 2px;
    }

    .datepicker--nav-title {
        border-radius: 4px;
        padding: 0 8px;

        [class="dark-only"] & {
            color: rgba($white, 0.8);
        }
    }

    .datepicker--nav-title i {
        font-style: normal;
        color: #9c9c9c;
        margin-left: 5px;
    }

    .datepicker--nav-title:hover {
        background: #f0f0f0;
    }

    .datepicker--nav-title.-disabled- {
        cursor: default;
        background: none;
    }

    .datepicker--buttons {
        display: none;
    }

    .datepicker--button {
        color: #4eb5e6;
        cursor: pointer;
        border-radius: 4px;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        height: 32px;
    }

    .datepicker--button:hover {
        color: #4a4a4a;
        background: #f0f0f0;
    }

    .datepicker--cell-day.-other-month-,
    .datepicker--cell-year.-other-decade- {
        color: #dedede;
    }

    .datepicker--cell-day.-other-month-:hover,
    .datepicker--cell-year.-other-decade-:hover {
        color: #c5c5c5;
    }

    .-disabled-.-focus-.datepicker--cell-day.-other-month-,
    .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
        color: #dedede;
    }

    .-selected-.datepicker--cell-day.-other-month-,
    .-selected-.datepicker--cell-year.-other-decade- {
        color: #fff;
        background: #a2ddf6;
    }

    .-selected-.-focus-.datepicker--cell-day.-other-month-,
    .-selected-.-focus-.datepicker--cell-year.-other-decade- {
        background: #8ad5f4;
    }

    .-in-range-.datepicker--cell-day.-other-month-,
    .-in-range-.datepicker--cell-year.-other-decade- {
        background-color: rgba(92, 196, 239, 0.1);
        color: #cccccc;
    }

    .-in-range-.-focus-.datepicker--cell-day.-other-month-,
    .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
        background-color: rgba(92, 196, 239, 0.2);
    }

    .datepicker--cell-day.-other-month-:empty,
    .datepicker--cell-year.-other-decade-:empty {
        background: none;
        border: none;
    }

    /* -------------------------------------------------
        Timepicker
       ------------------------------------------------- */
    .datepicker--time {
        border-top: 1px solid #efefef;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 4px;
        position: relative;
    }

    .datepicker--time.-am-pm- .datepicker--time-sliders {
        -webkit-flex: 0 1 138px;
        -ms-flex: 0 1 138px;
        flex: 0 1 138px;
        max-width: 138px;
    }

    .-only-timepicker- .datepicker--time {
        border-top: none;
    }

    .datepicker--time-sliders {
        -webkit-flex: 0 1 153px;
        -ms-flex: 0 1 153px;
        flex: 0 1 153px;
        margin-right: 10px;
        max-width: 153px;
    }

    .datepicker--time-label {
        display: none;
        font-size: 12px;
    }

    .datepicker--time-current {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        font-size: 14px;
        text-align: center;
        margin: 0 0 0 10px;
    }

    .datepicker--time-current-colon {
        margin: 0 2px 3px;
        line-height: 1;
    }

    .datepicker--time-current-hours,
    .datepicker--time-current-minutes {
        line-height: 1;
        font-size: 19px;
        font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
        position: relative;
        z-index: 1;
    }

    .datepicker--time-current-hours:after,
    .datepicker--time-current-minutes:after {
        content: "";
        background: #f0f0f0;
        border-radius: 4px;
        position: absolute;
        left: -2px;
        top: -3px;
        right: -2px;
        bottom: -2px;
        z-index: -1;
        opacity: 0;
    }

    .datepicker--time-current-hours.-focus-:after,
    .datepicker--time-current-minutes.-focus-:after {
        opacity: 1;
    }

    .datepicker--time-current-ampm {
        text-transform: uppercase;
        -webkit-align-self: flex-end;
        -ms-flex-item-align: end;
        align-self: flex-end;
        color: #9c9c9c;
        margin-left: 6px;
        font-size: 11px;
        margin-bottom: 1px;
    }

    .datepicker--time-row {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 11px;
        height: 17px;
        background: linear-gradient(to right, #dedede, #dedede) left 50%/100% 1px no-repeat;
    }

    .datepicker--time-row:first-child {
        margin-bottom: 4px;
    }

    .datepicker--time-row input[type="range"] {
        background: none;
        cursor: pointer;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        height: 100%;
        padding: 0;
        margin: 0;
        -webkit-appearance: none;
    }

    .datepicker--time-row input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
    }

    .datepicker--time-row input[type="range"]::-ms-tooltip {
        display: none;
    }

    .datepicker--time-row input[type="range"]:hover::-webkit-slider-thumb {
        border-color: #b8b8b8;
    }

    .datepicker--time-row input[type="range"]:hover::-moz-range-thumb {
        border-color: #b8b8b8;
    }

    .datepicker--time-row input[type="range"]:hover::-ms-thumb {
        border-color: #b8b8b8;
    }

    .datepicker--time-row input[type="range"]:focus {
        outline: none;
    }

    .datepicker--time-row input[type="range"]:focus::-webkit-slider-thumb {
        background: #5cc4ef;
        border-color: #5cc4ef;
    }

    .datepicker--time-row input[type="range"]:focus::-moz-range-thumb {
        background: #5cc4ef;
        border-color: #5cc4ef;
    }

    .datepicker--time-row input[type="range"]:focus::-ms-thumb {
        background: #5cc4ef;
        border-color: #5cc4ef;
    }

    .datepicker--time-row input[type="range"]::-webkit-slider-thumb {
        box-sizing: border-box;
        height: 12px;
        width: 12px;
        border-radius: 3px;
        border: 1px solid #dedede;
        background: #fff;
        cursor: pointer;
        transition: background 0.2s;
    }

    .datepicker--time-row input[type="range"]::-moz-range-thumb {
        box-sizing: border-box;
        height: 12px;
        width: 12px;
        border-radius: 3px;
        border: 1px solid #dedede;
        background: #fff;
        cursor: pointer;
        transition: background 0.2s;
    }

    .datepicker--time-row input[type="range"]::-ms-thumb {
        box-sizing: border-box;
        height: 12px;
        width: 12px;
        border-radius: 3px;
        border: 1px solid #dedede;
        background: #fff;
        cursor: pointer;
        transition: background 0.2s;
    }

    .datepicker--time-row input[type="range"]::-webkit-slider-thumb {
        margin-top: -6px;
    }

    .datepicker--time-row input[type="range"]::-webkit-slider-runnable-track {
        border: none;
        height: 1px;
        cursor: pointer;
        color: transparent;
        background: transparent;
    }

    .datepicker--time-row input[type="range"]::-moz-range-track {
        border: none;
        height: 1px;
        cursor: pointer;
        color: transparent;
        background: transparent;
    }

    .datepicker--time-row input[type="range"]::-ms-track {
        border: none;
        height: 1px;
        cursor: pointer;
        color: transparent;
        background: transparent;
    }

    .datepicker--time-row input[type="range"]::-ms-fill-lower {
        background: transparent;
    }

    .datepicker--time-row input[type="range"]::-ms-fill-upper {
        background: transparent;
    }

    .datepicker--time-row span {
        padding: 0 12px;
    }

    .datepicker--time-icon {
        color: #9c9c9c;
        border: 1px solid;
        border-radius: 50%;
        font-size: 16px;
        position: relative;
        margin: 0 5px -1px 0;
        width: 1em;
        height: 1em;
    }

    .datepicker--time-icon:after,
    .datepicker--time-icon:before {
        content: "";
        background: currentColor;
        position: absolute;
    }

    .datepicker--time-icon:after {
        height: 0.4em;
        width: 1px;
        left: calc(50% - 1px);
        top: calc(50% + 1px);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }

    .datepicker--time-icon:before {
        width: 0.4em;
        height: 1px;
        top: calc(50% + 1px);
        left: calc(50% - 1px);
    }

    .datepicker--cell-day.-other-month-,
    .datepicker--cell-year.-other-decade- {
        color: #dedede;
    }

    .datepicker--cell-day.-other-month-:hover,
    .datepicker--cell-year.-other-decade-:hover {
        color: #c5c5c5;
    }

    .-disabled-.-focus-.datepicker--cell-day.-other-month-,
    .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
        color: #dedede;
    }

    .-selected-.datepicker--cell-day.-other-month-,
    .-selected-.datepicker--cell-year.-other-decade- {
        color: #fff;
        background: #a2ddf6;
    }

    .-selected-.-focus-.datepicker--cell-day.-other-month-,
    .-selected-.-focus-.datepicker--cell-year.-other-decade- {
        background: #8ad5f4;
    }

    .-in-range-.datepicker--cell-day.-other-month-,
    .-in-range-.datepicker--cell-year.-other-decade- {
        background-color: rgba(92, 196, 239, 0.1);
        color: #cccccc;
    }

    .-in-range-.-focus-.datepicker--cell-day.-other-month-,
    .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
        background-color: rgba($primary-color, 0.2);
    }

    .datepicker--cell-day.-other-month-:empty,
    .datepicker--cell-year.-other-decade-:empty {
        background: none;
        border: none;
    }
}