/* FONT PATH
 * -------------------------- */

@font-face {
	font-family: 'themify';
	src:url('../../../../assets/fonts/themify/themify.eot?-fvbane');
	src:url('../../../../assets/fonts/themify/themify.eot?#iefix-fvbane') format('embedded-opentype'),
		url('../../../../assets/fonts/themify/themify.woff?-fvbane') format('woff'),
		url('../../../../assets/fonts/themify//themify.ttf?-fvbane') format('truetype'),
		url('../../../../assets/fonts/themify/themify.svg?-fvbane#themify') format('svg');
	font-weight: normal;
	font-style: normal;
}
