/**=====================
  3.32 Page CSS Start
==========================**/
/*test Nav bar color */
$page-name: theme,
  primary,
  secondary,
  success,
  danger,
  info,
  dark,
  warning;
$page-color: var(--theme-deafult),
  var(--theme-deafult),
  $secondary-color,
  $success-color,
  $danger-color,
  $info-color,
  $dark-color,
  $warning-color;

@each $var in $page-name {
  $i: index($page-name, $var);

  .pagination-#{$var} {
    .page-item {
      .page-link {
        color: nth($page-color, $i) !important;
      }

      &.active {
        .page-link {
          color: $white !important;
          background-color: nth($page-color, $i) !important;
          border-color: nth($page-color, $i);
        }
      }
    }
  }
}

$paginations-name: primary,
  secondary,
  success,
  danger,
  warning,
  info,
  light,
  dark;
$paginations-colors: $light-primary,
  $light-secondary,
  $light-success,
  $light-danger,
  $light-warning,
  $light-info,
  $light-white,
  $light-dark;

@each $var in $paginations-name {
  $i: index($paginations-name, $var);

  .pagin-border-#{$var} {
    .page-item {

      &.active,
      &:active {
        background-color: nth($paginations-colors, $i);
      }

      .page-link {
        border-color: nth($paginations-colors, $i);

        &:hover,
        &:focus {
          background-color: nth($paginations-colors, $i);
        }

        &:focus {
          box-shadow: unset;
        }
      }
    }
  }
}

.pagination-lg {
  .page-item {
    .page-link {
      @media (max-width: 576px) {
        font-size: calc(15px + (20 - 15) * ((100vw - 320px) / (576 - 320)));
        padding: calc(12px + (12 - 12) * ((100vw - 320px) / (576 - 320))) calc(12px + (24 - 12) * ((100vw - 320px) / (576 - 320)));
      }
    }
  }
}

.pagination {

  @media (max-width:339px) {
    justify-content: center;
  }

  .page-item {
    &:active {
      background-color: unset;
    }

    .page-link {
      &.rounded-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
      }
    }
  }
}

/**=====================
    3.32 Page CSS Ends
==========================**/