/**=====================
  5.2 Grid CSS Start
==========================**/
.grid-showcase {
  margin-bottom: -10px;

  span,
  .grid-wrapper {
    display: block;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
    background-color: rgba(242, 243, 247, 0.7);
  }

}

.grid-align {
  margin-bottom: -16px;

  .row {
    padding: 15px 0;
    min-height: 7rem;
    margin-bottom: 16px;
  }
}

.grid-vertical {
  min-height: 150px;
  padding: 10px;
  color: $black;

}

table {
  &.table-bordered {
    thead {
      tr {
        th {
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

/**=====================
    5.2 Grid CSS Ends
==========================**/