/**=====================
  2.33 Tour CSS Start
==========================**/

.introjs-helperLayer {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
}

.introjs-tooltipReferenceLayer {
  background-color: transparent;
}

.introjs-helperNumberLayer {
  color: $white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  background: $danger-color;
  background: linear-gradient(to bottom, $tour-color 0%, $tour-color 100%);
  border: 3px solid $white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}

.introjs-arrow {
  border: 12px dashed $white;

  &.top {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: $tour-color;
    border-left-color: transparent;
  }

  &.top-right {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: $tour-color;
    border-left-color: transparent;
  }

  &.top-middle {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: $tour-color;
    border-left-color: transparent;
  }

  &.right {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: $tour-color;
  }

  &.right-bottom {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: $tour-color;
  }

  &.bottom {
    border-top-color: $tour-color;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  &.left {
    border-top-color: transparent;
    border-right-color: $tour-color;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  &.left-bottom {
    border-top-color: transparent;
    border-right-color: $tour-color;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

.introjs-tooltip {
  background-color: $tour-color;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
  color: $white;
  border-color: $tour-color;
  min-width: calc(275px + (310 - 275) * ((100vw - 320px) / (1920 - 320)));
  max-width: 400px;
  min-height: 90px;
}

.introjs-button {
  border: 1px dashed $light-semi-gray;
  text-shadow: 1px 1px 0 $white;
  color: $theme-body-font-color  !important;
  background-color: $white;
  background-image: linear-gradient($white, $white);
  font-size: 13px;

  &:hover {
    border-color: $light-semi-gray;
  }

  &:focus,
  &:active {
    background-image: linear-gradient($light-gray, $light-semi-gray);
  }
}

.introjs-skipbutton {
  color: $tour-color;
}

.introjs-prevbutton {
  &.introjs-fullbutton {
    border: 1px dashed $light-semi-gray;
  }
}

.introjs-disabled {
  color: $tour-color;
  border-color: $light-semi-gray;

  &:hover,
  &:focus {
    color: $tour-color;
    border-color: $light-semi-gray;
  }
}

.introjs-bullets {
  ul {
    li {
      a {
        background: rgba(255, 255, 255, 0.3);

        &:hover {
          background: $white;
        }

        &.active {
          background: $white;
        }
      }
    }
  }
}

.introjs-progress {
  background-color: $light-color;
}

.introjs-progressbar {
  background-color: $tour-color;
}

.introjs-hint:hover>.introjs-hint-pulse {
  border: 5px dashed rgba(60, 60, 60, 0.57);
}

.introjs-hint-pulse {
  border: 5px dashed rgba(60, 60, 60, 0.27);
  background-color: rgba(136, 136, 136, 0.24);
}

.introjs-hint-dot {
  border: 10px dashed rgba(146, 146, 146, 0.36);
}

.flex-grow-1 {
  .tour-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    .tour-dot {
      font-size: 5px;
    }
  }
}

.tour-blog {
  height: 350px;

  @media (max-width:992px) {
    height: calc(200px + (350 - 200) * ((100vw - 320px) / (992 - 320)));
  }

  img {
    height: 100%;
    object-fit: cover;
  }
}

.info {
  h6 {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
  }

  span {
    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
  }
}


.tour-email {
  display: flex;
  flex-direction: column;

  @media (max-width:576px) {
    align-items: center;
  }
}

.block-ellipsis {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 26px !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-img-style {
  h5 {
    line-height: 25px;
  }
}

.list-inline {
  .list-inline-item {
    &.b-r-gray {
      border-right: 1px dashed $gray-60;
    }
  }
}

.social-tour {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 26px;

  .list-inline {
    display: flex;
    justify-content: center;
    gap: calc(12px + (26 - 12) * ((100vw - 320px) / (1920 - 320)));

    .list-inline-item {
      margin-right: 0;

      a {
        padding: 0px;

        i {
          font-size: 18px;
        }
      }
    }

    @media (max-width: 599px) {
      display: none;
    }
  }

  .float-sm-end {
    min-width: 81px;
  }
}

.tour-mb-space {
  margin-bottom: calc(2px + (8 - 2) * ((100vw - 320px) / (1920 - 320)));
}

.like-comment {
  .list-inline {
    justify-content: flex-start;

    @media (max-width:324px) {
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 8px;
    }

    .list-inline-item {
      [dir="rtl"] & {
        border-right: 0;
        border-left: 1px dashed $gray-60;
      }

      &:last-child {
        [dir="rtl"] & {
          border-right: 0;
          border-left: unset;
        }
      }
    }
  }
}

/**=====================
  2.33 Tour CSS Ends
==========================**/