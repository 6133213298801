/**=====================
    3.38 Sweet-alert CSS Start
==========================**/

.swal-modal {
    .swal-title {
        font-size: 24px;
    }

    .swal-text {
        font-size: 17px;
    }
}

/**=====================
    3.38 Sweet-alert CSS ends
==========================**/