.card {
    margin-bottom: 30px;
    border: 1px solid #ccc; 
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 3px;
    box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
    padding: 8px;
}

.levelChk:checked {
    border-color: var(--theme-deafult) !important;//#0d6efd;
    background-color: var(--theme-deafult) !important; //#0d6efd;
}

.btn-secondary {
    background-color: #ffffff !important;
    // border-color: none !important;
    border: none;
    // -webkit-tap-highlight-color: transparent;
    transform: translateZ(0);
    color: black;
   --bs-btn-hover-color: #000000;
}
.svg {
    vertical-align: baseline;
    height: 10px;
}
.custom-cancel-button {
    border: 1px solid var(--theme-deafult) !important;//#7470BA !important;
    color: var(--theme-deafult) !important;//#7470BA !important;
  }
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .default_border{
    border: 2px solid var(--theme-deafult) !important;
  }
  
  .content-blur {
    filter: blur(5px);
  }
  
  .highlighted-button {
    background: linear-gradient(45deg, #380060, rgba(151, 112, 219, 0.9803921569)) !important;
    color: white !important;
    border-color: #bc88fb !important;
}
.custom-card {
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.upload-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.upload-header h5 {
  margin: 0;
  font-size: 1.5rem;
}

.upload-buttons,
.upload-file {
  display: flex;
  gap: 15px;
}

.upload-buttons button,
.upload-file button {
  flex-shrink: 0;
}

.custom-cancel-button {
  background-color: #f8f9fa;
  color: #343a40;
  border: 1px solid #ced4da;
}

.custom-cancel-button:hover {
  background-color: #e9ecef;
}

.upload-button {
  width: 100%;
  padding: 10px;
}

.upload-button:disabled {
  background-color: #d6d8db;
  color: #6c757d;
  cursor: not-allowed;
}

// .button:{
//   background-color: #d6d8db;
//   color: #6c757d;
// }

.aligned-list {
  list-style: none; /* Removes default bullet points */
  padding: 0;
  margin: 0;
  padding-left: 10px;
  font-family: monospace; /* Ensures uniform character width */
}

.aligned-list li {
  display: flex; /* Horizontally align elements */
  align-items: center; /* Vertically align items */
}

.custom-modal {
  max-height: 70vh;
  overflow-y: auto;
}

.aligned-list .range {
  width: 60px; /* Fixed width for alignment, adjust if needed */
  text-align: left; /* Align range to the left */
}

.aligned-list .value {
  flex: 1; /* Take up remaining space for the value */
  text-align: left;
}
